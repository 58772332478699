<template>
  <div class="bg-white shadow-xl border-l-2 cust_card border-rgray-100"
       style="position: fixed; height: 100%; width: 100%; min-width: 80px; z-index: 100; top: 0; overflow-x: hidden; overflow-y: auto; transition: 0.5s; border-radius: 0px !important; right: 0;">
    <div class="px-5 py-2 bg-primary text-white heading-2 font-bold flex flex-wrap items-center justify-between">
      <div>
        <p class="mb-2 flex flex-wrap">Image Editor</p>
      </div>
      <div class="flex gap-4 mb-2">
        <Button :btnSize="'medium'" :textColor="'text1'" :btnColor="'gray1'" :btnText="'Discard'" @buttonAction="emitData(false, null)" />
        <Button :btnSize="'medium'" :textColor="'white'" :btnColor="'success'" :btnText="'Save'" @buttonAction="uploadImage" />
      </div>
    </div>
    <div wrap>
      <div class="p-4" >
        <div class="imageDiv">
          <div wrap class="p-2 flex justify-between">
            <!-- <div class="heading-2">
              Image
            </div> -->
            <div class="flex items-center">
              <div class="d_icon_child">
                <span :class="!movileView ? 'px-3 py-2 heading-4' : 'heading-6 py-2 px-2' " class=" text-white cursor-pointer bg-primary rounded-xl" @click="startAddingText()">
                  <i class="fa-solid fa-font"></i>
                </span>
                <span class="tooltiptext heading-4 bg-transparent whitespace-nowrap rounded w-32">Add Text</span>
              </div>
              <div class="d_icon_child">
                                <!-- <span :class="`${!movileView ? 'px-3 py-2 heading-4' : 'heading-6 py-2 px-2'} ${isDrawingMode ? '' : 'bg-primary'}`" :style="isDrawingMode ? `background-color: ${selectedColor}` : ''" class=" text-white cursor-pointer  ml-2 rounded-xl" @click="isDrawingMode = !isDrawingMode;enableDrawingMode()"> -->
                <span :class="`${!movileView ? 'px-3 py-2 heading-4' : 'heading-6 py-2 px-2'} ${isDrawingMode ? 'bg-secondary' : 'bg-primary'}` " class=" text-white cursor-pointer  ml-2 rounded-xl" @click="isDrawingMode = !isDrawingMode;enableDrawingMode()">
                  <i class="fas fa-pencil"></i>
                </span>
                <span class="tooltiptext heading-4 bg-transparent whitespace-nowrap rounded w-32">Free Draw</span>
              </div>
              <div class="d_icon_child">
                                <!-- <span :class="`${!movileView ? 'px-3 py-2 heading-4' : 'heading-6 py-2 px-2'} ${highLighterOn ? '' : 'bg-primary'}`" :style="highLighterOn ? `background-color: ${selectedColor}` : ''" class=" text-white cursor-pointer  ml-2 rounded-xl" @click="highLighterOn = !highLighterOn;enableHighlighterMode()"> -->
                <span :class="`${!movileView ? 'px-3 py-2 heading-4' : 'heading-6 py-2 px-2'} ${highLighterOn ? 'bg-secondary' : 'bg-primary'}` " class=" text-white cursor-pointer  ml-2 rounded-xl" @click="highLighterOn = !highLighterOn;enableHighlighterMode()">
                  <i class="fas fa-highlighter"></i>
                </span>
                <span class="tooltiptext heading-4 bg-transparent whitespace-nowrap rounded w-32">Highlighter</span>
              </div>
              <div class="d_icon_child">
                <span :class="!movileView ? 'px-3 py-2 heading-4' : 'heading-6 py-2 px-2' " class=" text-white cursor-pointer bg-primary ml-2 rounded-xl" @click="addShape('rect')">
                  <i class="fa-regular fa-square-full"></i>
                </span>
                <span class="tooltiptext heading-4 bg-transparent whitespace-nowrap rounded w-32">Add Square</span>
              </div>
              <div class="d_icon_child">
                <span :class="!movileView ? 'px-3 py-2 heading-4' : 'heading-6 py-2 px-2' " class=" text-white cursor-pointer bg-primary ml-2 rounded-xl" @click="addShape('circle')">
                  <i class="fa-regular fa-circle"></i>
                </span>
                <span class="tooltiptext heading-4 bg-transparent whitespace-nowrap rounded w-32">Add Circle</span>
              </div>
              <div class="d_icon_child">
                <span :class="!movileView ? 'px-3 py-2 heading-4' : 'heading-6 py-2 px-2' " class=" text-white cursor-pointer bg-primary ml-2 rounded-xl" @click="addShape('line')">
                  <i class="fa-solid fa-minus"></i>
                </span>
                <span class="tooltiptext heading-4 bg-transparent whitespace-nowrap rounded w-32">Add Line</span>
              </div>
              <div class="d_icon_child">
                <span :class="!movileView ? 'px-3 py-2 heading-4' : 'heading-6 py-2 px-2' " class=" text-white cursor-pointer bg-primary ml-2 rounded-xl" @click="addShape('arrow')">
                  <i class="fa-solid fa-arrow-right-long"></i>
                </span>
                <span class="tooltiptext heading-4 bg-transparent whitespace-nowrap rounded w-32">Add Arrow</span>
              </div>
              <div class="d_icon_child">
                <span :class="!movileView ? 'px-3 py-2 heading-4' : 'heading-6 py-2 px-2' " class=" text-white cursor-pointer bg-error ml-2 rounded-xl" @click="deleteSelected">
                  <i class="fa-solid fa-trash-alt"></i>
                </span>
                <span class="tooltiptext heading-4 bg-transparent whitespace-nowrap rounded w-40">Remove Selection</span>
              </div>
              <div class="d_icon_child">
                <span :class="!movileView ? 'px-3 py-2 heading-4' : 'heading-6 py-2 px-2' " class="text-white cursor-pointer bg-error ml-2 rounded-xl" @click="clearAll">
                  <i class="fas fa-eraser"></i>
                </span>
                <span class="tooltiptext heading-4 bg-transparent whitespace-nowrap rounded w-32">Clear All</span>
              </div>
              <div class="pl-2">
                <input type="color" class="cursor-pointer" v-model="selectedColor" @input="updateColor" />
              </div>
            </div>
          </div>
          <div class="pl-2 -mt-1.5 heading-6 text-error font-semibold" v-if="isAddingText">*Click where you want to put text on the image.</div>
          <div class="divider"></div>
          <div id="app">
            <canvas id="canvas" height="400"></canvas>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/View/components/globalButton.vue'
import { fabric } from 'fabric';

export default {
  components: {
    Button
  },
  data () {
    return {
      highLighterOn: false,
      isDrawingMode: false,
      selectedITEM: null,
      movileView: false,
      resultURL: '',
      canvas: null,
      currentTool: null,
      isAddingText: false,
      innerWidth: window.innerWidth - 50,
      innerHeight: window.innerHeight - 160,
      selectedObject: null,
      imageObject: null,
      selectedColor: '#000000'  // Single color property for text and shapes
    }
  },
  props: ['empObj', 'title', 'ratio', 'entityName', 'imageToCrop'],
  created () {
    window.addEventListener('resize', this.handleResize);
  },
  mounted () {
    this.handleResize()
    this.canvas = new fabric.Canvas('canvas');
    this.canvas.setWidth(this.innerWidth);
    this.canvas.setHeight(this.innerHeight);
    if (this.imageToCrop) {
      this.loadImageFromURL(this.imageToCrop);
    }
    this.canvas.on('mouse:down', this.handleCanvasClick);
    window.addEventListener('keydown', this.handleKeydown);
    this.canvas.on('object:selected', this.handleObjectSelected);
    this.canvas.on('selection:cleared', this.clearSelectedObject);
  },
  beforeDestroy() {
    this.canvas.off('mouse:down', this.handleCanvasClick);
    this.canvas.off('object:selected', this.handleObjectSelected);
    this.canvas.off('selection:cleared', this.clearSelectedObject);
    window.removeEventListener('keydown', this.handleKeydown);
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    clearAll() {
      if (this.imageObject) {
        // Keep the image and remove all other objects
        this.canvas.getObjects().forEach((obj) => {
          if (obj !== this.imageObject) {
            this.canvas.remove(obj);
          }
        });
        this.canvas.renderAll(); // Re-render the canvas to update the display
      }
      this.isAddingText = false;
      this.isDrawingMode = false;
      this.highLighterOn = false;
      this.selectedITEM = null;
      this.selectedObject = null;
    },
    handleKeydown(event) {
      if (event.key === 'Delete') { // Check if the Delete key was pressed
        this.deleteSelected();
      }
    },
    handleResize() {
      this.innerWidth = window.innerWidth - 50;
      this.innerHeight = window.innerHeight - 160;
      if (window.innerWidth < 556) {
        this.movileView = true
      } else {
        this.movileView = false
      }
      this.initializeCanvas();
    },
    initializeCanvas() {
      if (this.canvas) {
        this.canvas.setWidth(this.innerWidth);
        this.canvas.setHeight(this.innerHeight);
      }
    },
  loadImageFromURL(url) {
    fabric.Image.fromURL(url, (img) => {
      this.canvas.clear();
      const { width, height } = img;
      const canvasAspect = this.canvas.width / this.canvas.height;
      const imageAspect = width / height;
      if (canvasAspect > imageAspect) {
          img.scaleToHeight(this.canvas.height);
        } else {
          img.scaleToWidth(this.canvas.width);
        }
        img.set({
          left: (this.canvas.width - img.getScaledWidth()) / 2,
          top: (this.canvas.height - img.getScaledHeight()) / 2,
          selectable: false,
          evented: false,
        });
        this.canvas.add(img);
        this.canvas.sendToBack(img);
        this.imageObject = img;
    });
  },
  startAddingText() {
    this.highLighterOn = false
    this.isDrawingMode = false
    this.canvas.isDrawingMode = false;
    this.isAddingText = true;
    this.currentTool = 'text';
    // const text = prompt('Enter text:');
    //   if (text) {
    //     const textObj = new fabric.Text(text, {
    //       left: 100,
    //       top: 100,
    //       fontSize: 30,
    //       fill: this.selectedColor, // Apply selected color
    //       selectable: true
    //     });
    //     this.canvas.add(textObj);
    //   }
  },
  handleCanvasClick(e) {
    this.selectedITEM = null
    console.log('e------>', e)
    this.selectedITEM = e.target
    if (this.isAddingText && this.currentTool === 'text') {
      const text = prompt('Enter text:');
      if (text) {
        const pointer = this.canvas.getPointer(e.e);
        const textObj = new fabric.Text(text, {
          left: pointer.x,
          top: pointer.y,
          fontSize: 30,
          fill: this.selectedColor, // Apply selected color
          selectable: true
        });
        this.canvas.add(textObj);
      }
      this.isAddingText = false;
    }
  },
  enableHighlighterMode() {
    this.isDrawingMode = false
    console.log('this.highLighterOn', this.highLighterOn)
    if (this.highLighterOn) {
      // let color = 'rgba(255, 255, 0, 0.5)'
      this.canvas.isDrawingMode = true;
      this.canvas.freeDrawingBrush.color = this.hexToRgba(this.selectedColor, 0.5); // Yellow highlighter color with 50% opacity
      this.canvas.freeDrawingBrush.width = 20; // Set the drawing width
      this.canvas.freeDrawingBrush.strokeLineCap = 'round'; // Smooth lines
      this.canvas.freeDrawingBrush.strokeLineJoin = 'round';
    } else {
      this.canvas.isDrawingMode = false;
    }
  },
  enableDrawingMode() {
    this.highLighterOn = false
    if (this.isDrawingMode) {
      this.canvas.isDrawingMode = true;
      this.canvas.freeDrawingBrush.color = this.selectedColor; // Set the drawing color
      this.canvas.freeDrawingBrush.width = 5; // Set the drawing width
      this.canvas.freeDrawingBrush.strokeLineCap = 'round'; // Smooth lines
      this.canvas.freeDrawingBrush.strokeLineJoin = 'round';
    } else {
      this.canvas.isDrawingMode = false;
    }
    // console.log('isDrawingMode', this.isDrawingMode)
    // this.currentTool = 'draw';
    // this.canvas.isDrawingMode = true;
    // this.canvas.freeDrawingBrush.color = this.selectedColor; // Set the drawing color
    // this.canvas.freeDrawingBrush.width = 5; // Set the drawing width
    // this.canvas.freeDrawingBrush.strokeLineCap = 'round'; // Smooth lines
    // this.canvas.freeDrawingBrush.strokeLineJoin = 'round';
  },
  addShape(shapeType) {
    this.highLighterOn = false
    this.isDrawingMode = false
    this.canvas.isDrawingMode = false;
    this.currentTool = shapeType;
    this.canvas.isDrawingMode = false;

  const commonOptions = {
    left: 100,
    top: 100,
    stroke: this.selectedColor, // Apply selected color
    strokeWidth: 2,
    selectable: true
  };

  let shape;

  switch (shapeType) {
    case 'rect': {
      shape = new fabric.Rect({
        ...commonOptions,
        fill: 'transparent',
        width: 100,
        height: 100
      });
      break;
    }
    case 'arrow': {
      shape = this.createArrow();
      break;
    }
    case 'circle': {
      shape = new fabric.Circle({
        ...commonOptions,
        fill: 'transparent',
        radius: 50
      });
      break;
    }
    case 'line': {
      shape = new fabric.Line([100, 100, 200, 200], {
        ...commonOptions
      });
      break;
    }
  }

  if (shape) {
    this.canvas.add(shape);
    this.canvas.setActiveObject(shape); // Automatically select the newly added shape
    this.canvas.renderAll(); // Render the canvas to update the display
  }
  },
  createArrow() {
    return new fabric.Path('M 0 0 L 100 0 L 90 -10 M 100 0 L 90 10', {
      fill: 'transparent',
      stroke: this.selectedColor,
      strokeWidth: 2,
      left: 100,
      top: 100,
      selectable: true,
      evented: true
    });
  },
  hexToRgba(hex, alpha) {
    let r = 0, g = 0, b = 0;
    // 3 digits
    if (hex.length === 4) {
      r = parseInt(hex[1] + hex[1], 16);
      g = parseInt(hex[2] + hex[2], 16);
      b = parseInt(hex[3] + hex[3], 16);
    }
    // 6 digits
    else if (hex.length === 7) {
      r = parseInt(hex[1] + hex[2], 16);
      g = parseInt(hex[3] + hex[4], 16);
      b = parseInt(hex[5] + hex[6], 16);
    }
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  },
  updateColor() {
    if (this.isDrawingMode) {
      this.canvas.freeDrawingBrush.color = this.selectedColor
    }
    if (this.highLighterOn) {
      const rgbaColor = this.hexToRgba(this.selectedColor, 0.5);
      this.canvas.freeDrawingBrush.color = rgbaColor
    }
    if (this.selectedObject) {
      if (this.selectedObject.type === 'text') {
        this.selectedObject.set('fill', this.selectedColor);
      } else if (['rect', 'circle', 'line', 'path'].includes(this.selectedObject.type)) {
        this.selectedObject.set('stroke', this.selectedColor);
      }
      this.canvas.renderAll();
    }
    console.log('this.selectedITEM', this.selectedITEM)
    console.log('this.selectedITEM', this.selectedITEM.type)
    if (this.selectedITEM !== null) {
      if (this.selectedITEM.type === 'text') {
        this.selectedITEM.set('fill', this.selectedColor);
      } else if (['rect', 'circle', 'line', 'path'].includes(this.selectedITEM.type)) {
        this.selectedITEM.set('stroke', this.selectedColor);
      }
      this.canvas.renderAll();
    }
  },
  handleObjectSelected(e) {
    console.log('e---------->', e)
    this.selectedObject = e.target;
  },
  clearSelectedObject() {
    this.selectedObject = null;
  },
  deleteSelected() {
  const activeObjects = this.canvas.getActiveObjects(); // Get all selected objects
  
  if (activeObjects.length > 0) {
    activeObjects.forEach(object => {
      this.canvas.remove(object); // Remove each selected object
    });
    this.canvas.discardActiveObject(); // Clear the active selection
    this.canvas.renderAll(); // Re-render the canvas to update the display
  }
},
  emitData(isUpdated, file) {
    let url = this.resultURL;
    this.$root.$emit('closeUpdatedProfileDialog', {
      isUpdated: isUpdated,
      url: url,
      entityName: this.entityName,
      file: file
    })
  },
  uploadImage() {
    const dataURL = this.canvas.toDataURL('image/jpeg', 1);
    this.resultURL = this.canvas.toDataURL('image/jpeg', 1);
    this.dataURItoBlob(dataURL).then(file => {
      this.emitData(true, file);
    });
  },
  dataURItoBlob(dataURI) {
    return new Promise((resolve) => {
      const byteString = atob(dataURI.split(',')[1]);
      const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
      const arrayBuffer = new ArrayBuffer(byteString.length);
      const uint8Array = new Uint8Array(arrayBuffer);
      for (let i = 0; i < byteString.length; i++) {
        uint8Array[i] = byteString.charCodeAt(i);
      }
      const blob = new Blob([uint8Array], { type: mimeString });
      resolve(blob);
    });
  }
}
}
</script>

<style scoped>
.placeholder {
  text-align: center;
  padding: 20px;
  background-color: lightgray;
}
.optionsCard {
  position: absolute;
  bottom: 122px;
  right: 5px;
}
.input-437 .v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted {
  padding: 0 !important;
}
.colorDiv {
  width: 30px;
  height: 20px;
  border: solid 1px lightgray;
}
.displayFlex {
  display: flex;
  align-items: center;
}
.previewDiv {
  border: solid 1px lightgray;
}
.imageDiv {
  border: solid 1px lightgray;
}
.resultDiv {
  width: 350px;
}
.color-picker-controls {
  margin-top: 10px;
  display: flex;
  gap: 10px;
  align-items: center;
}
.color-picker-controls label {
  margin-right: 5px;
}
.d_icon_child {
  position: relative;
}

.tooltiptext {
  visibility: hidden;
  color: #fff;
  text-align: center;
  padding: 0px 0px;
  background-color: #0000009c;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  bottom: -30px;
  left: -40px;
}


.d_icon_child:hover .tooltiptext {
  visibility: visible;
}
</style>
