<template>
  <div>
    <div class="h-screen px-2 overflow-hidden">
      <div class="card top_primary_card font-bold text-gray1 p-3 rounded-xl w-full">
        <div class="flex items-start justify-start pl-4">
          <img src="@/assets/images/ZizbeyJobsLogo.svg" />
          <div class="heading-2 text-primary font-semibold pl-1">ZizbeyJobs</div>
        </div>
      </div>
      <div v-if="!ShowErr" class="py-2 overflow-auto " :style="`max-height:` + cardHeight + `px;overflow: auto; !important`">
        <div class="card bg-white rounded-xl p-4 my-2">
          <div :class="!movileView ? '' : 'flex items-center justify-between'" class="mb-2">
              <div class="">
                <div class="text-primary heading-5 font-semibold">{{jrtitle}}<span class="pl-1 text-gray4 heading-7">({{isFromName === 'job' ? 'Job' : 'Request'}})</span></div>
                <div class="text-text2 heading-7 font-semibold"><i class="fas fa-calendar pr-1"></i>{{jrDate | dateTimeFilter}}</div>
              </div>
            <!-- <div class="text-text2 heading-4 font-bold">Provide Related Details: Upload Image and Add Description:</div> -->
            <Button :class="!movileView ? 'pt-2 text-right justify-end' : ''" :isSqure="true" :disabled="images.length === 0" :btnSize="'small'" style="letter-spacing: 1.4px;" :textColor="'white'" :btnColor="'primary'" :btnText="'Send Attachment'" @buttonAction="saveButton()"/>
          </div>
          <div class="text-text2 heading-5 font-semibold">Provide Related Details: Upload Images:</div>
          <!-- <div class="py-4 pt-2">
            <p class="label_css">Enter additional details here</p>
            <textarea rows="3" cols="50"
              v-model="description"
              placeholder="Enter additional details here..."
              class=" w-full h-full p-4 bg-transparent border border-gray2 ring-transparent outline-none focus:border-primary focus:ring-1 rounded-md resize-none z-10"
            ></textarea>
          </div> -->
          <div class="py-4 pt-4">
            <p class="label_css">Attachments:</p>
            <div class="cursor-pointer"
              :class="['dropzone', { 'dragging': isDragging }]"
              @drop.prevent="handleDrop"
              @dragover.prevent="handleDragOver"
              @dragleave="handleDragLeave"
              @click="triggerFileInput"
            >
              <input type="file" ref="fileInput" accept="image/*, video/*" @change="handleFiles" multiple class="hidden" />
              Drag & Drop images here or click to select
            </div>
          </div>
          <div class="pt-4">
            <p class="label_css" v-if="images.length > 0">Preview: ({{images.length}})</p>
            <div class="flex flex-wrap gap-6 pt-2">
              <div v-for="(image, index) in images" :key="index" class="image-card card border border-gray2 rounded-md p-1 w-52 h-52 flex flex-col items-center relative">
                <div class=" text-white heading-5 cursor-pointer bg-error px-3 py-1 opacity-90 rounded-full absolute right-1 top-1" @click="removeImage(index)">
                  <i class="fa-solid fa-times"></i>
                </div>
                <div v-if="image.type2 === 'image'" class=" text-white heading-5 cursor-pointer bg-primary px-3 py-1 opacity-90 rounded-full absolute right-10 top-1" @click="editImage(index)">
                  <i class="fa-solid fa-pen-to-square"></i>
                </div>
                <div class=" w-full h-full">
                  <img v-if="image.type2 === 'image'" :src="image.url" alt="image preview" style="object-fit: contain;height: 150px;" class="w-full h-full object-cover" />
                  <div v-if="image.type2 === 'video'" class="flex items-center justify-center w-full h-full bg-gray-200">
                    <i class="fas fa-play text-4xl text-white"></i>
                  </div>
                </div>
                <div class="text-center text-sm text-text2 mt-2" style="line-break:anywhere;">{{ image.name }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="ShowErr">
        <div class="bg-gray-200 w-full px-2 md:px-0 h-screen flex items-center justify-center">
          <div class="bg-white border border-gray-200 flex flex-col items-center justify-center px-20 py-8 rounded-lg shadow-2xl">
              <p v-if="displayTitle === 'Detail Submitted.'" class="text-6xl md:text-7xl lg:text-6xl font-bold tracking-wider text-gray-300"><i class="fa-solid fa-check fa-fade"></i></p>
              <p v-if="displayTitle !== 'Detail Submitted.'" class="text-6xl md:text-7xl lg:text-6xl font-bold tracking-wider text-red-400"><i class="fa-solid fa-times fa-fade"></i></p>
              <p class="text-gray-500 mt-3 py-2 border-y-2 text-center">{{displayTitle}}</p>
          </div>
        </div>
       </div>
       <div v-if="isExpire" class="popup_overlay">
        <div class="bg-gray-200 w-full px-2 md:px-0 h-screen flex items-center justify-center">
          <div class="bg-white border border-gray-200 flex flex-col items-center justify-center px-20 py-8 rounded-lg shadow-2xl">
              <p class="text-6xl md:text-7xl lg:text-6xl font-bold tracking-wider text-red-400"><i class="fa-solid fa-triangle-exclamation fa-fade"></i></p>
              <!-- <p class="text-gray-500 mt-3 py-2 border-y-2 text-center">Please Check Your Link and Try Again Later</p> -->
              <p class="text-gray-500 mt-3 py-2 border-y-2 text-center">Link has Expired at {{apiData | formatTime}}</p>
          </div>
        </div>
      </div>
      </div>
      <div class="bg-screen_bg card popup_overlay" v-if="showEditFunc">
        <div class="custom_dialog" :style="`width: 100%; overflow: auto; height:100vh;`">
          <div class="card top_primary_card font-bold text-gray1 p-1 m-4 rounded-xl">
            <div class="flex items-start justify-start pl-4">
              <img src="@/assets/images/ZizbeyJobsLogo.svg" />
              <div class="heading-2 text-primary font-semibold pl-1">ZizbeyJobs</div>
            </div>
          </div>
         <div class="card bg-white rounded-xl p-4 m-4 pt-0 mt-2 mb-2">
           <div class="">
            <div :class="!movileView ? '' : 'flex items-center justify-between'" class="mb-2">
              <div class="text-text2 font-semibold heading-4">Image Modifier</div>
              <div :class="!movileView ? 'pt-1 text-right justify-end' : ''" class="flex items-center">
                <Button :btnSize="'medium'" :textColor="'text1'" :btnColor="'gray1'" :btnText="'Reset & Close'" @buttonAction="closeBtn(0)"/>
                <Button class="pl-2" :btnSize="'medium'" :textColor="'white'" :btnColor="'primary'" :btnText="'Save & Close'" @buttonAction="closeBtn(1)"/>
              </div> 
            </div>
             <!-- <div class="flex flex-wrap justify-between items-center">
                <div class="text-text2 font-semibold heading-4">Image Modifier </div>  -->
                <!-- <div class="text-text2 font-semibold heading-4">Image Modifier <span class="text-primary pl-1 cursor-pointer heading-5 font-semibold" v-if="doneOnce" @click="reloadagain()">(Reset)</span></div>  -->
              <!-- <div class="flex items-center">
                <Button :btnSize="'medium'" :textColor="'text1'" :btnColor="'gray1'" :btnText="'Reset & Close'" @buttonAction="closeBtn(0)"/> -->
                <!-- <Button class="pl-2" :btnSize="'medium'" :textColor="'white'" :btnColor="'primary'" :btnText="'Save & Close'" @buttonAction="closeBtn(1)"/> -->
                <!-- <Button :isSqure="true"  style="letter-spacing: 1.4px;" :textColor="'white'" :btnColor="'primary'" :btnText="''" class="heading-5" @buttonAction=""/> -->
                <!-- <Button class="pl-2 heading-5" :isSqure="true"  style="letter-spacing: 1.4px;" :textColor="'white'" :btnColor="'primary'" :btnText="''" @buttonAction=""/> -->
                <!-- <button class="bg-primary px-3 py-2 rounded-full hover:shadow-xl" @click="showEditFunc = false;doneOnce=false;">
                  <i class="fas fa-times text-white h-5 w-5 pt-1"></i>
                </button> -->
              <!-- </div> -->
            <!-- </div> -->
            <div class="flex items-center pt-2">
              <Button :iconClass="'fas fa-crop'" :isSqure="true" :btnSize="'small'" style="letter-spacing: 1.4px;" :textColor="'white'" :btnColor="'primary'" :btnText="'Crop Image'" @buttonAction="cropStart()"/>
              <Button :iconClass="'fas fa-pencil'" class="pl-2" :isSqure="true" :btnSize="'small'" style="letter-spacing: 1.4px;" :textColor="'white'" :btnColor="'primary'" :btnText="'Edit Image'" @buttonAction="cropStart2()"/>
            </div>
           </div>
         </div>
         <div class="card bg-white rounded-xl p-4 m-4 mt-0 flex justify-center" v-if="selectedImage.type2 === 'image'">
          <img v-if="selectedImage" :src="selectedImage.url" alt="Selected image" :style="!movileView ? '`width: auto`' : `width: auto;max-height: 100%;height:` + imageDivHeight2 + `px;`" />
        </div>
         <div class="card bg-white rounded-xl p-4 m-4 mt-0 flex justify-center" v-if="selectedImage.type2 === 'video'">
          <video v-if="selectedImage" id="myVideo" autoplay class="mt-1" controls :style="`width: 100%;height:` + imageDivHeight2 + `px;`">
            <source  type="video/mp4" align="center" justify="center" class="ma-0" :src="selectedImage.url">
            </video>
        </div>
       </div>
       <div v-if="showLogoCropper" fullscreen>
        <imageCroper :entityName="selectedImage.name" :imageToCrop="selectedImage.url" :title="'Image Crop'" :ratio="4/4" v-if="showLogoCropper"/>
      </div>
       <div v-if="showLogoEditor" fullscreen>
        <imageEditor :entityName="selectedImage.name" :imageToCrop="selectedImage.url" :title="'Image Editor'" :ratio="4/4" v-if="showLogoEditor"/>
      </div>
     </div>
     <loader
        v-if="$store.getters.showLoader"
        v-bind:data="$store.getters.loaderMessage"
      ></loader>
      <snak-bar
        v-if="$store.getters.showAlert"
        v-bind:status="$store.getters.showAlert"
        v-bind:message="$store.getters.alertMessage"
        v-bind:color="$store.getters.alertColor"
      >
      </snak-bar>
  </div>
</template>
<script>
var moment = require('moment')
import imageCroper from '@/View/endUsers/component/imageCroper.vue'
import imageEditor from '@/View/endUsers/component/imageEditor.vue'
import MyJobApp from '@/api/MyJobApp.js'
import Button from '@/View/components/globalButton.vue'
import snakBar from '@/View/components/SnakBar.vue'
import Loader from '@/View/components/Loader.vue'
export default {
  components: {
    imageCroper,
    imageEditor,
    Button,
    snakBar,
    Loader
  },
  data() {
    return {
      jrDate: '',
      jrtitle: '',
      displayTitle: '',
      tenpFIles: [],
      showLogoEditor: false,
      showLogoCropper: false,
      apiData: '',
      isExpire: false,
      sessionToken: '',
      imageDivHeight2: window.innerHeight - 240,
      selectedImage: null,
      showEditFunc: false,
      ShowErr: false,
      cardHeight: 0,
      movileView: false,
      images: [],
      dubArray: [],
      isDragging: false,
      doneOnce: false,
      isFromName: '',
      chanesIndex: 0,
      changesArray: null,
      backupUrl: '',
    };
  },
  created() {
    window.addEventListener('resize', this.resizeWindowHandler)
  },
  destroyed() {
    window.removeEventListener("resize", this.resizeWindowHandler);
  },
  mounted () {
    this.resizeWindowHandler()
    console.log("this.$route.params.customerId", this.$route.name)
    if (this.$route.name === 'rsd') {
      this.isFromName = 'request'
    } else {
      this.isFromName = 'job'
    }
    this.sessionToken = this.$route.params.infoId
    this.getTokenExp()
    this.$root.$on('closeProfileDialog', (data) => {
      console.log('----------', data)
      if (data.isUpdated) {
        this.getDublicateDataSet()
        this.doneOnce = true;
        const index = this.images.findIndex(img => img.name === this.selectedImage.name);
        console.log('index')
        if (index !== -1) {
          // this.chanesIndex = index
          // this.changesArray = this.images[index]
          console.log('index', this.images[index])
          this.images[index] = data;
          this.images[index].name = data.entityName;
          this.images[index].type2 = 'image';
        }
        this.selectedImage.url = data.url
        this.showLogoCropper = false;
      } else {
        this.showLogoCropper = false;
      }
      console.log('this.images11',  this.images)
    });
    this.$root.$on('closeUpdatedProfileDialog', (data) => {
      console.log('----------', data)
      if (data.isUpdated) {
        this.getDublicateDataSet()
        this.doneOnce = true;
        const index = this.images.findIndex(img => img.name === this.selectedImage.name);
        console.log('index')
        if (index !== -1) {
          // this.chanesIndex = index
          // this.changesArray = this.images[index]
          console.log('index', this.images[index])
          this.images[index] = data;
          this.images[index].name = data.entityName;
          this.images[index].type2 = 'image';
        }
        this.selectedImage.url = data.url
        this.showLogoEditor = false;
      } else {
        this.showLogoEditor = false;
      }
      console.log('this.images11',  this.images)
    });
  },
  beforeDestroy() {
    this.$root.$off('closeProfileDialog')
    this.$root.$off('closeUpdatedProfileDialog')
  },
  methods: {
    getDublicateDataSet () {
      console.log('this.dubArray ----->', this.dubArray)
      console.log('this.selectedImage ----->', this.selectedImage)
      for (let index = 0; index < this.dubArray.length; index++) {
        if (this.selectedImage.name === this.dubArray[index].name) {
          this.chanesIndex = index
          this.changesArray = this.dubArray[index]
        }
      }
    },
    getTokenExp() {
      this.$store.dispatch("SetLoader", {status: true,message: "Loading...",});
      MyJobApp.CheckJobTokenLink(
        this.sessionToken,
        this.isFromName,
        (response) => {
          if (response.Data !== '') {
            this.apiData = response.Data
            var startTime = moment(new Date());
            var endTime = moment(new Date(response.Data));
            if (endTime.isBefore(startTime)) {
              this.isExpire = true
            } else {
              this.isExpire = false
            }
          } else {
            this.ShowErr = true
            this.displayTitle = 'Invalid Request'
          }
          this.jrtitle = response.tl
          this.jrDate = response.ct
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        },
        (err) => {
          this.$store.dispatch('SetAlert', {showAlert: true, message: err.message, color: 'error'})
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        }
      );
    },
    saveButton () {
      console.log('this.images', this.images)
      this.images.forEach(element => {
        if (element.file.name === undefined) {
          element.file.name = element.entityName
        }
      });
      if (this.isFromName === 'job') {
        this.$store.dispatch("SetLoader", {status: true,message: "Sending...",});
        MyJobApp.UploadJobTokenLinkAtt(
          this.sessionToken,
          this.images.map(img => img.file),
          (response) => {
            console.log('response.Data', response.Data)
            this.displayTitle = response.Data
            this.$store.dispatch('SetAlert', {showAlert: true, message: response.Data, color: 'success'})
            this.$store.dispatch('SetLoader', {status: false, message: ''})
            this.ShowErr = true
            },
            (error) => {
              console.log('error', error);
              this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
              this.$store.dispatch('SetLoader', {status: false, message: ''})
            }
        );
      } else {
        this.$store.dispatch("SetLoader", {status: true,message: "Sending...",});
        MyJobApp.UploadRequestLinkAtt(
          this.sessionToken,
          this.images.map(img => img.file),
          (response) => {
            this.displayTitle = response.Data
            this.$store.dispatch('SetAlert', {showAlert: true, message: response.Data, color: 'success'})
            this.$store.dispatch('SetLoader', {status: false, message: ''})
            this.ShowErr = true
            },
            (error) => {
              console.log('error', error);
              this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
              this.$store.dispatch('SetLoader', {status: false, message: ''})
            }
        );
      }
      console.log('this.', this.images)
    },
    closeBtn(from) {
      if (from === 0) {
        // Reset to the original URL
        if (this.selectedImage) {
          this.selectedImage.url = this.backupUrl;
        }
        this.backupUrl = '';
        if (this.changesArray !== null) {
          this.images[this.chanesIndex] = this.changesArray
        }
        setTimeout(() => {
          this.showEditFunc = false; // Close the edit dialog
        }, 100);
      } else {
        this.showEditFunc = false; // Just close the edit dialog
        this.changesArray = null
        this.chanesIndex = 0
      }
    },
    resizeWindowHandler() {
      this.cardHeight = window.innerHeight - 110
      if (window.innerWidth < 556) {
        this.movileView = false
      } else {
        this.movileView = true
      }
    },
    handleDrop(event) {
      event.preventDefault();
      this.isDragging = false;
      const files = Array.from(event.dataTransfer.files);
      this.processFiles(files);
    },
    handleDragOver(event) {
      event.preventDefault();
      this.isDragging = true;
    },
    handleDragLeave(event) {
      event.preventDefault();
      this.isDragging = false;
    },
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    handleFiles(event) {
      const files = Array.from(event.target.files);
      this.processFiles(files);
    },
    reloadagain () {
      this.selectedImage.url = this.backupUrl
      this.doneOnce = false
      this.backupUrl = ''
    },
    editImage (index) {
      this.selectedImage = this.images[index];
      this.backupUrl = this.images[index].url; // Save the original URL
      this.showEditFunc = true; // Show the edit dialog
    },
    cropStart () {
      this.showLogoCropper = true
    },
    cropStart2 () {
      this.showLogoEditor = true
    },
    removeImage(index) {
      this.images.splice(index, 1);
      this.dubArray.splice(index, 1);
    },
    processFiles(files) {
      files.forEach(file => {
        if (file instanceof File && (file.type.includes('image') || file.type.includes('video'))) {
          // Create a new FileReader to read the file's URL
          const reader = new FileReader();
          reader.onload = (e) => {
            this.images.push({
              name: file.name,
              type: file.type,
              size: file.size,
              lastModified: file.lastModified,
              url: e.target.result, // Base64 encoded URL
              type2: file.type.includes('image') ? 'image' : 'video',
              file: file // Store the actual File object
            });
            this.dubArray.push({
              name: file.name,
              type: file.type,
              size: file.size,
              lastModified: file.lastModified,
              url: e.target.result, // Base64 encoded URL
              type2: file.type.includes('image') ? 'image' : 'video',
              file: file // Store the actual File object
            });
          };
          reader.readAsDataURL(file);
        } else {
          alert(`File type ${file.type} is not supported. Please upload images or videos.`);
        }
      });
      console.log('images', this.images);
    }
  }
}
</script>
<style scoped>
.dropzone {
  min-height: 150px;
  line-height: 150px;
  position: relative;
  border: 2px dashed #d1d5db;
  border-radius: 8px;
  padding: 16px;
  text-align: center;
  transition: background-color 0.3s ease;
}

.dropzone.dragging {
  background-color: rgba(0, 0, 0, 0.1);
  border-color: rgba(0, 0, 0, 0.5);
}

.image-card {
  position: relative;
  overflow: hidden;
}


.image-overlay {
  position: relative;
  width: 100%;
  height: 100%;
}

.image-overlay img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

.image-overlay::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Dark background with transparency */
  opacity: 0;
  transition: opacity 0.3s ease;
}

.image-overlay:hover::before {
  opacity: 1;
}

.overlay-content {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  color: white;
  padding: 4px 0;
  background: rgba(0, 0, 0, 0.5); /* Slightly darker background for text */
  opacity: 0;
  transition: opacity 0.3s ease;
}

.image-overlay:hover .overlay-content {
  opacity: 1;
}
</style>
